// extracted by mini-css-extract-plugin
export var additionalBg = "blog-module--additionalBg--Wrquz";
export var back = "blog-module--back--645mF";
export var bgImg = "blog-module--bgImg--DMn34";
export var bgLayer = "blog-module--bgLayer--pTjYN";
export var blogThumbnail = "blog-module--blogThumbnail--LxxEO";
export var bottomhr = "blog-module--bottomhr--58RMP";
export var btnGithub = "blog-module--btnGithub--SW33Q";
export var btnNav = "blog-module--btnNav--w72HP";
export var btnView = "blog-module--btnView--T6LYC";
export var cardBody = "blog-module--cardBody--C6xuS";
export var cardDiv = "blog-module--cardDiv--gOxUM";
export var catDiv = "blog-module--catDiv--HDXYp";
export var contentArea = "blog-module--contentArea--JNI1I";
export var descBox = "blog-module--descBox--VCP2p";
export var itemContentArea = "blog-module--itemContentArea--4MG0f";
export var mt4 = "blog-module--mt-4--b+HWS";
export var projectTitle = "blog-module--projectTitle--1UFXU";
export var pt_80 = "blog-module--pt_80--CgmnF";
export var sideBtnGrp = "blog-module--sideBtnGrp--KIKcy";
export var sideBtnGrpDiv = "blog-module--sideBtnGrpDiv--quXWR";
export var sidebar = "blog-module--sidebar--mDsHj";
export var sidebarRsp = "blog-module--sidebarRsp--Y4tBm";
export var sidebarRspDiv = "blog-module--sidebarRspDiv--j-Nhb";
export var tag = "blog-module--tag--zY8U6";
export var thumbnailImg = "blog-module--thumbnailImg--UMuj9";
export var thumbnailImgDiv = "blog-module--thumbnailImgDiv--M0Gqb";
export var titleDiv = "blog-module--titleDiv--dS0X7";
export var tophr = "blog-module--tophr--FGmYk";
export var weird = "blog-module--weird--SaNNw";