import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import BlogLayout from "../../components/blog/blogLayout"
import Items from "../../components/blog/items"

const Blog = () => {
  const QUERY = useStaticQuery(
    graphql`
      query {
        allFile(
          filter: { relativeDirectory: { eq: "blog" } }
          sort: { fields: childMarkdownRemark___frontmatter___id }
        ) {
          nodes {
            childMarkdownRemark {
              frontmatter {
                id
                title
                slug
                desc
              }
            }
          }
        }
      }
    `
  )

  const allData = QUERY.allFile.nodes
  console.log(allData)

  return (
    <>
      <BlogLayout>
        <Items blog={allData} />
      </BlogLayout>
    </>
  )
}

export default Blog
