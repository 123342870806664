import React from "react"
import PropTypes from "prop-types"
import Layout from "../layout"
import Seo from "../seo"
import * as styles from "../../styles/blog.module.css"

const BlogLayout = ({ children }) => {
  return (
    <>
      <Layout>
        <Seo title="Blogs" />
        <div className={styles.projectTitle + ` dark_green_bg`}></div>
        <div
          className={styles.tophr + ` fixed-top`}
          style={{ zIndex: "1" }}
        ></div>

        <div
          // className={`d-flex w-100 lightParallax`}
          className={`w-100 lightParallax`}
          style={{ margin: "0 auto" }}
        >
          <div className="parallax4 w-100"></div>

          <div
            className={styles.pt_80 + ` w-100`}
            style={{
              paddingTop: "80px",
              margin: "0 auto",
            }}
          >
            <main className="w-100" style={{ margin: "0 auto!important" }}>
              {children}
            </main>
          </div>

          <div className="parallax2 w-100"></div>
        </div>

        <hr className={styles.bottomhr} />
      </Layout>
    </>
  )
}

BlogLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default BlogLayout
