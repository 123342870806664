import React from "react"
import * as styles from "../../styles/blog.module.css"
import { navigate } from "gatsby"

const Items = ({ blog }) => {
  return (
    <>
      <div
        className={styles.contentArea + ` parallax4 h-100 w-100`}
        style={{ minHeight: "100vh" }}
      >
        <div className={styles.titleDiv + ` px-3 w-100 pt-0 pb-2`}>
          <div className="col">
            <hr className={styles.tophr} style={{ padding: "1px" }} />
          </div>
          <h1>Blog</h1>
          <div className="col">
            <hr className={styles.tophr} style={{ padding: "1px" }} />
          </div>
        </div>

        <div className="container w-100 py-4 mb-3">
          <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-xl-3 g-5">
            {blog.map(p => (
              <div
                className="bg-transparent col mb-1 position-relative"
                key={p.childMarkdownRemark.frontmatter.id}
              >
                <div
                  className={styles.cardDiv + ` card shadow-sm bg-transparent`}
                >
                  <div style={{ display: "grid" }}>
                    <div
                      style={{
                        gridArea: "1/1",
                        position: "relative",
                        display: "grid",
                      }}
                      className={styles.bgLayer}
                    ></div>

                    <div
                      style={{
                        gridArea: "1/1",
                        position: "relative",
                        display: "grid",
                      }}
                      className={styles.cardBody + ` card-body`}
                    >
                      <div className="col w-100 pt-2 pb-4">
                        <div
                          className={styles.blogThumbnail + ` w-100`}
                          style={{ height: "200px" }}
                        ></div>
                      </div>

                      <div className="col">
                        <p className="card-text m-0">
                          {p.childMarkdownRemark.frontmatter.title}
                        </p>

                        <hr />

                        <p
                          className="text-muted m-0"
                          style={{
                            fontSize: "17px",
                            marginTop: "6px",
                            lineHeight: "1.6",
                            textShadow: "none",
                          }}
                        >
                          {p.childMarkdownRemark.frontmatter.desc}
                        </p>

                        <hr />

                        <div className="d-grid gap-2">
                          <button
                            type="button"
                            className={styles.btnView + ` text-dark btn`}
                            onClick={() => {
                              navigate(
                                "/blog/" +
                                  p.childMarkdownRemark.frontmatter.slug
                              )
                            }}
                          >
                            View More
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default Items
